<template>
	<div class="dropdown" :class="cssClass">
		<button
			v-on:click.prevent="
				toggleDropdown($event);
				sendGrafanaEvent(title);
			"
			class="btn btn-link dropdown-toggle arrowtoggle"
			type="button"
			data-toggle="dropdown"
			aria-haspopup="true"
			aria-expanded="false"
		>
			<i class="zmdi" :class="iconButton"></i>
			{{ changeVariusGenre(title) }}
		</button>
		<div class="dropdown-menu dropdown-menu-right" v-if="toggle">
			<template v-if="!showFullLoader">
				<span class="dropdown-header">{{ searchTitle }}</span>

				<div class="form-group" v-if="showSearch">
					<input
						v-model="searchValue"
						type="search"
						class="form-control search-in-list"
						name="search-in-list"
						placeholder="Buscar en la lista..."
					/>
				</div>

				<button
					v-if="buttonFilterVisible()"
					class="deletefilter-button"
					v-on:click="deleteFilters($event)"
				>
					Eliminar Filtros
				</button>

				<div class="dropdown-inner">
					<div v-if="filteredList.length === 0" class="pl-3 pb-2 small">
						Sin resultados...
					</div>
					<a
						v-for="(item, index) in filteredList"
						:key="keyField ? item[keyField] : index"
						v-on:click.prevent="
							onSelectItem(item, keyField ? item[keyField] : index);
							sendGrafanaEventEnter(changeVariusGenre(item[textField]));
						"
						:id="'itemfilter-' + index"
						class="dropdown-item arrowitem"
						:class="{
							selected:
								changeVariusGenre(title) == changeVariusGenre(item[textField]),
						}"
						href="#"
					>{{ changeVariusGenre(item[textField]) }}</a
					>
				</div>
			</template>

			<div v-if="showFullLoader" class="loader-wrapper loader-component">
				<div class="loader" title="Cargando ...">
					<svg class="circular-loader" viewBox="25 25 50 50">
						<circle
							class="loader-path"
							cx="50"
							cy="50"
							r="20"
							fill="none"
							stroke="#09f"
							stroke-width="4"
						/>
					</svg>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import _ from "lodash";

export default {
	name: "Select",

	props: {
		title: {
			type: String,
			default: "Seleccioná un valor",
			required: false,
		},
		searchTitle: {
			type: String,
			default: "Filtrar:",
			required: false,
		},
		keyField: {
			type: String,
			default: null,
			required: false,
		},
		textField: {
			type: String,
			default: null,
			required: true,
		},
		cssClass: {
			type: String,
			default: "",
			required: false,
		},
		iconButton: {
			type: String,
			default: "zmdi-filter-list",
			required: false,
		},
		showFullLoader: {
			type: Boolean,
			default: true,
			required: false,
		},
		showSearch: {
			type: Boolean,
			default: true,
			required: false,
		},
		showButtonFilter: {
			type: Boolean,
			default: true,
			required: false,
		},
		list: {
			type: Array,
		},
		filterList: {
			type: Array,
		},
		onSelect: {
			type: Function,
			default: function () {},
		},
		orderedList: {
			type: Boolean,
			required: false,
			default: true,
		},
		defaultSelectedItem: {
			type: Object,
			required: false,
			default: () => {
				return {
					id: null,
					name: "",
				};
			},
		},
	},

	data() {
		return {
			searchValue: "",
			selectedItem: { name: "" },
			focusedItemIndex: 0,
			toggle: false,
		};
	},

	computed: {
		filteredList: function () {
			return this.findBy(this.list, this.searchValue, this.textField);
		},
	},

	mounted() {
		window.addEventListener('keydown', this.handleKeyPress);
	},
	destroyed() {
		window.removeEventListener('keydown', this.handleKeyPress);
	},

	methods: {
		buttonFilterVisible() {
			if (
				this.$route.name === "24-hours" ||
				this.$route.name === "24-horas-filtro"
			) {
				return false;
			} else {
				return this.showButtonFilter;
			}
		},

		changeVariusGenre(name) {
			if (name === "Various" || name === "VARIOUS" || name === "various") {
				return "Varios";
			}
			return name;
		},
		deleteFilters() {
			const imgToggleFilter = document.querySelector(".arrowtoggle");
			imgToggleFilter.click();
			this.$bus.$emit("delete-filter", true);
			window.document.body.click();
			this.toggle = false;
		},

		toggleDropdown(event) {
			this.toggle = true;
			const activeDropdown = event.currentTarget.closest(".dropdown");

			//Limpio los otros dropdowns:
			document.querySelectorAll(".dropdown").forEach(function (dropdown) {
				if (dropdown !== activeDropdown) {
					dropdown.classList.remove("show");
				}
			});

			//Toggle del dropdown clickeado:
			activeDropdown.classList.toggle("show");

			//Foco al buscador del select:
			if (this.showSearch) {
				const search = this.$el.querySelector(".search-in-list");
				if (search) search.focus();
			}
		},

		findBy(list, value, column) {
			const self = this;

			value = self.accentFold(value).toLowerCase();

			const result = list.filter(function (item) {
				const text = self.accentFold(item[column]).toLowerCase();
				return text.includes(value);
			});

			if (self.orderedList) {
				return _.orderBy(result, (p) => p[column].toLowerCase(), column);
			} else {
				return result;
			}
		},

		accentFold(inStr) {
			return inStr.replace(
				/([àáâãäå])|([ç])|([èéêë])|([ìíîï])|([ñ])|([òóôõöø])|([ß])|([ùúûü])|([ÿ])|([æ])/gi,
				function (str, a, c, e, i, n, o, s, u, y, ae) {
					if (a) return "a";
					if (c) return "c";
					if (e) return "e";
					if (i) return "i";
					if (n) return "n";
					if (o) return "o";
					if (s) return "s";
					if (u) return "u";
					if (y) return "y";
					if (ae) return "ae";
				}
			);
		},

		onSelectItem(item, key) {
			this.selectedItem = item; //No se usa internamente por ahora
			this.onSelect(item, key);
		},

		handleKeyPress(e) {
			const isArrowKey = ['ArrowDown', 'ArrowUp'].includes(e.key);
			if (isArrowKey) {
				const items = document.querySelectorAll('.arrowitem');
				if (e.key === 'ArrowDown' && this.focusedItemIndex < items.length - 1) {
					this.focusedItemIndex++;
				} else if (e.key === 'ArrowUp' && this.focusedItemIndex > 0) {
					this.focusedItemIndex--;
				}
				e.preventDefault();
				const itemToFocus = items[this.focusedItemIndex];
				itemToFocus?.focus();
			}
		},
		sendGrafanaEvent(name) {
			const self = this;
			tplay.sendGrafanaEvent(
				tplay.grafana.event.BUTTON,
				{ name: name, screen: self.$route.name },
				tplay.grafana.actions.ENTER
			);
		},
		sendGrafanaEventEnter(name) {
			const self = this;
			tplay.sendGrafanaEvent(
				tplay.grafana.event.BUTTON,
				{ name: name, sub_type: "filter", screen: self.$route.name },
				tplay.grafana.actions.ENTER
			);
		},
	},
	watch: {
		defaultSelectedItem(value) {
			//console.log('defaultSelectedItem', value);
			this.selectedItem = value;
		},
	},
};
</script>
